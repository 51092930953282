import React, { memo } from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import { Title } from '@lib/components/v2/Title';
import { Checkbox } from '@lib/components/v2/Form';
import { getFields } from '@lib/utils/v2/getFields';
import { isLocalizedStringDefined, localizedString } from '@languages';

import { ENABLE_ONE_DOC_CONDITION } from '@spotMobileConfig';

import { Fields } from '@lib/components/v2/Contents/VerifyDetails/Fields';
import classes from './VerifyDetails.style.module.scss';
import { getValidations } from './verifyDetailsValidations';

const VerifyDetails = memo(
  ({
    pageTitle = 'Are your ID details correct?',
    idType,
    idTypeTitle,
    cardType,
    flowType,
    country = 'AU',
    countryOfIssue,
    onChange,
    address,
    errors,
    checkConfirm,
    addressData,
    useIdNumber,
    isSubmiting,
    ...restProps
  }) => {
    let NEW_VOI_FLOW_V2_LOADING_DETAILS_HEADING = '';

    if (isLocalizedStringDefined('app.VOI_FLOW_V2_VERIFY_DETAILS_DESC')) {
      NEW_VOI_FLOW_V2_LOADING_DETAILS_HEADING = localizedString(
        'app.VOI_FLOW_V2_VERIFY_DETAILS_DESC',
        idTypeTitle
      );
    }

    if (
      isLocalizedStringDefined('app.VOI_FLOW_V2_VERIFY_DETAILS_DESC_AU_DRIVER_LICENCE') &&
      localizedString('app.VOI_FLOW_V2_VERIFY_DETAILS_DESC_AU_DRIVER_LICENCE') &&
      country === 'AU' &&
      idType.match(/LICENCE/i)
    ) {
      NEW_VOI_FLOW_V2_LOADING_DETAILS_HEADING = localizedString(
        'app.VOI_FLOW_V2_VERIFY_DETAILS_DESC_AU_DRIVER_LICENCE'
      );
    }

    const fields = getFields({
      idType,
      countryOfIssue,
      addressData,
      useIdNumber,
      values: restProps,
      flowType
    });
    const formValidations = getValidations({
      idType,
      countryOfIssue,
      values: restProps,
      flowType,
      cardType
    });

    let containerClassName = classes.container;
    if (ENABLE_ONE_DOC_CONDITION) {
      containerClassName = classes['container-one-doc'];
    }

    const handleChange = ({ id, value } = {}) => onChange(id, value);

    return (
      <div className={classes.wrapper}>
        <Title data-testid="details-heading" title={pageTitle} />
        {NEW_VOI_FLOW_V2_LOADING_DETAILS_HEADING && (
          <div className={classes.description}>{NEW_VOI_FLOW_V2_LOADING_DETAILS_HEADING}</div>
        )}
        <div className={containerClassName} aria-live="polite">
          <Fields
            classes={classes}
            formValidations={formValidations}
            handleChange={handleChange}
            isSubmiting={isSubmiting}
            country={country}
            address={address}
            addressData={addressData}
            fields={fields}
          />
          <Checkbox
            className={classNames(classes['check-confirm'])}
            id="confirm"
            value={checkConfirm}
            onChange={(value) => onChange('checkConfirm', value)}
            label={localizedString('app.VOI_FLOW_V2_VERIFY_DETAILS_CONSENT')}
            dataTestId="details-checkbox"
            labelDataTestId="details-consentTxt"
            disabled={isSubmiting}
          />
        </div>
      </div>
    );
  }
);

VerifyDetails.propTypes = {
  idType: PropTypes.string,
  cardType: PropTypes.string,
  onChange: PropTypes.func,
  errors: PropTypes.object,
  /* Form data */
  firstName: PropTypes.string,
  middleName: PropTypes.string,
  lastName: PropTypes.string,
  dateOfBirth: PropTypes.string,
  expiryDate: PropTypes.string,
  idNumber: PropTypes.string,
  licenceNumber: PropTypes.string,
  licenceVersion: PropTypes.string,
  passportNumber: PropTypes.string,
  /* Confirmation */
  checkConfirm: PropTypes.bool,
  checklicenceNumber: PropTypes.bool,
  checklicenceVersion: PropTypes.bool,
  checkpassportNumber: PropTypes.bool,

  pageTitle: PropTypes.string,
  idTypeTitle: PropTypes.string,
  flowType: PropTypes.string,
  country: PropTypes.string,
  countryOfIssue: PropTypes.string,
  address: PropTypes.any,
  addressData: PropTypes.any,
  useIdNumber: PropTypes.bool,
  isSubmiting: PropTypes.bool
};

VerifyDetails.defaultProps = {
  idType: 'NZL_DRIVERLICENCE',
  errors: {},
  firstName: '',
  middleName: '',
  lastName: '',
  dateOfBirth: '',
  expiryDate: '',
  passportNumber: '',
  idNumber: '',
  licenceNumber: '',
  licenceVersion: '',
  onChange: () => {}
};

export default VerifyDetails;
